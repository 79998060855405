import React from 'react';
import logo from './saloiaSite.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header>
    </div>
  );
}

export default App;


// 67680725066-2phmgn8ippne01kndb978jfn122kt7n2.apps.googleusercontent.com
// 0bC6QqsXLZUMv0YUNiIUA_Js
